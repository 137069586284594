


























































import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import FillableDocumentFilter from "@/dto/archive/FillableDocumentFilter";
import {FillableDocumentType} from "@/dto/archive/FillableDocumentType";
import DocumentService from "@/services/DocumentService";
import FillableDocumentDataDTO from "@/dto/archive/FillableDocumentDataDTO";
import RouteNames from "@/router/RouteNames";
import {FillableDocumentStatus} from "@/dto/archive/FillableDocumentStatus";
import Workspaces from "@/state/Workspaces";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
    computed: {
        FillableDocumentStatus() {
            return FillableDocumentStatus
        }
    }
})
export default class F8822List extends Vue {
    @Auth.Getter("hasAdministrativePrivileges")
    private hasAdministrativePrivileges!: boolean;

    private successful = false;

    private message = "";

    @AppModule.State
    private loading!: boolean;

    @AppModule.Action
    private startLoading!: () => void;

    @AppModule.Action
    private stopLoading!: () => void;

    private routes = RouteNames

    private forms: Array<FillableDocumentDataDTO> = [];

    mounted() {
        this.loadForms()
    }

    handleClick(form: FillableDocumentDataDTO) {
        if (form.signedFile) {
            Vue.prototype.$docUtils.download(form.signedFile.id);
        }
        if (form.status === FillableDocumentStatus.NEW) {
            this.openExistDocument(form.id)
        }
    }

    openExistDocument(id: number) {
        if (this.hasAdministrativePrivileges) {
            this.$router.push({name: this.routes.FORM_8822_B, params: {documentId: String(id)}});
        }
    }

    openNewDocument() {
        DocumentService.registerF8822B(Workspaces.getCurrent.id).then(
            (res) => {
                this.$router.push({name: this.routes.FORM_8822_B, params: {documentId: String(res.data.id)}});
            }
        )
    }

    loadForms(){
        this.startLoading();
        this.message = "";
        const filter = new FillableDocumentFilter();
        filter.requestingCompanyId = Workspaces.getCurrent.id;
        filter.documentType = FillableDocumentType.F_8822_B;
        return DocumentService.getFillableByFilter(filter).then(
            success => this.forms = success.data.data,
            error => this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string
        ).then(() => this.stopLoading());
    }

	private processEvent(form: FillableDocumentDataDTO) {
		if (form.publicKey != null) {
			this.$router.push(
				{
					name: RouteNames.FORM_8822_B,
					params: {
						publicId: `${form.publicKey}`
					}
				}
			)
		} else {
			Vue.prototype.$docUtils.download(form.signedFile!.id)
		}
	}
}
